import cookie from 'cookie';
import { GetServerSidePropsContext } from 'next';
import { generateSessionId } from 'lib/session/session';

export const USER_ID_COOKIE_NAME = 'FCID';
const USER_ID_COOKIE_MAX_AGE = 365 * 24 * 60 * 60;

export default function getOptimizelyUserId(ctx: GetServerSidePropsContext) {
  const { req, res } = ctx;

  // The cookie is http only, so we only can read/modify it by changing the requests
  const cookies = cookie.parse(req.headers.cookie ?? '');
  const userIdFromCookie = cookies[USER_ID_COOKIE_NAME];

  // generate an id by using the one read before or generating a new one
  const userId = userIdFromCookie ? userIdFromCookie : generateSessionId();

  // Set the cookie with a userid anew
  res.setHeader(
    'Set-Cookie',
    cookie.serialize(USER_ID_COOKIE_NAME, userId, {
      maxAge: USER_ID_COOKIE_MAX_AGE,
      expires: new Date(Date.now() + USER_ID_COOKIE_MAX_AGE * 1000),
      httpOnly: false,
      path: '/',
      sameSite: 'lax',
      domain: process.env.COOKIE_BASE_URL,
    }),
  );

  return userId;
}
